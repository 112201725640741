import isEmptyish from '@tmap/mmm-core/isEmptyish';
import { getStageInfo, stageOrder } from './stageOrder';

export default function getApplicationStartURL(userListItem, incentiveKey) {
  const startingUrlStub = getStageInfo(stageOrder[0]).url;

  if (isEmptyish(userListItem) || userListItem?.status === 'removed') {
    return `/favorites/continue?id=${incentiveKey}&url=${encodeURIComponent(`/apply/${startingUrlStub}?itemId={{_id}}`)}`;
  }
  return `/apply/${startingUrlStub}?itemId=${userListItem._id}`;
}
